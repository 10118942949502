export const PermissionManager = {
    rerunDatasetPerm: 'rerun_dataset',
    deleteDatasetPerm: 'delete_dataset',

    restoreAnnotationPerm: 'restore_annotation',
    deleteAnnotationPerm: 'delete_annotation',
    reviewAnnotationPerm: 'review_annotation',

    catAnnotationUploadPerm: 'cat_annotation_upload',
    updateInformationPerm: 'update_information',
    betaTesterPerm: 'beta_tester',
    dataReadinessEditor: 'data_readiness_editor',
};
