import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import PercentIcon from '@mui/icons-material/Percent';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { api } from '../../../../services';

const MODAL_HEIGHT = '100vh';

const MarketShareModal = (props) => {
    const { open, onClose, projectMarket, saveMarket, fetchMarket, options, fetchOptions } = props;
    const [categories, setCategories] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const listRef = React.useRef(null);
    const theme = useTheme();

    const styles = {
        scrollContainer: {
            height: MODAL_HEIGHT,
            overflowY: 'auto',
            padding: `0 ${theme.spacing(4)}`,
            justifyContent: 'center',
            display: 'flex',
        },
        separator: {
            border: `1px solid ${theme.palette.divider}`,
            width: '100%',
        },
        center: {
            width: '100%',
            maxWidth: '800px',
        },
    };

    useEffect(() => {
        if (open) {
            fetchMarket();
            fetchOptions();
        }
    }, [open, fetchMarket, fetchOptions]);

    useEffect(() => {
        const cat = {};
        projectMarket.forEach((item) => {
            if (!cat[item.category]) {
                cat[item.category] = { title: item.category, subcategories: [] };
            }
            cat[item.category].subcategories.push({ subCategory: item.sub_category, percentage: item.percentage });
        });
        setCategories(cat);
    }, [projectMarket]);

    const handleRemoveCategory = (categoryKey) => {
        const updated = { ...categories };
        delete updated[categoryKey];
        setCategories(updated);
    };
    const allCategoriesSelected = () => {
        return Object.keys(options).length === Object.values(categories).length;
    };

    const handleAddCategory = () => {
        const newCategoryKey = `new_category_${Object.keys(categories).length + 1}`;
        setCategories({
            ...categories,
            [newCategoryKey]: {
                title: `New Category ${Object.keys(categories).length + 1}`,
                subcategories: [],
            },
        });
        setTimeout(() => {
            if (listRef.current) {
                listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
            }
        }, 100);
    };

    const handleEditCategoryTitle = (categoryKey, value) => {
        const updated = { ...categories };
        updated[categoryKey].title = value;

        updated[categoryKey].subcategories =
            options[value]?.map((subCategory) => ({
                subCategory,
                percentage: 0,
            })) || [];

        setCategories(updated);

        const keys = Object.keys(categories);
        console.log(keys[keys.length - 1], categoryKey);
        if (keys[keys.length - 1] === categoryKey) {
            setTimeout(() => {
                if (listRef.current) {
                    listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
                }
            }, 100);
        }
    };

    const handleChange = (categoryKey, index, field, value) => {
        const updated = { ...categories };
        updated[categoryKey].subcategories[index][field] = field === 'percentage' ? parseFloat(value) || 0 : value;
        setCategories(updated);
    };

    const handleSave = () => {
        if (!validateFields()) return;

        const payload = [];
        Object.entries(categories).forEach(([categoryKey, categoryData]) => {
            categoryData.subcategories.forEach((sub) => {
                if (sub.subCategory) {
                    payload.push({
                        category: categoryData.title,
                        sub_category: sub.subCategory,
                        percentage: sub.percentage,
                    });
                }
            });
        });
        saveMarket(payload);
        onClose();
    };

    const validateFields = () => {
        const errors = {};

        Object.entries(categories).forEach(([categoryKey, categoryData]) => {
            if (!categoryData.title.trim()) {
                errors[categoryKey] = { title: 'Category title cannot be empty', subcategories: [] };
            } else {
                errors[categoryKey] = { title: '', subcategories: [] };
            }

            categoryData.subcategories.forEach((sub, index) => {
                const subErrors = {};
                if (!sub.subCategory.trim()) {
                    subErrors.subCategory = 'Subcategory cannot be empty';
                }
                if (sub.percentage === '' || isNaN(sub.percentage)) {
                    subErrors.percentage = 'Percentage is required';
                }
                errors[categoryKey].subcategories[index] = subErrors;
            });
        });

        setValidationErrors(errors);
        return !Object.values(errors).some(
            (catError) =>
                catError.title ||
                catError.subcategories.some((subError) => subError.subCategory || subError.percentage),
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>Edit Market</DialogTitle>
            <DialogContent sx={styles.scrollContainer} ref={listRef}>
                <Box sx={styles.center}>
                    {Object.entries(categories).map(([categoryKey, categoryData]) => (
                        <Box key={categoryKey} sx={{ mb: 4 }}>
                            <hr style={styles.separator} />

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 2,
                                    mt: 2,
                                    mx: 'auto',
                                    width: '40%',
                                }}
                            >
                                <Select
                                    value={categoryData.title}
                                    onChange={(e) => handleEditCategoryTitle(categoryKey, e.target.value)}
                                    fullWidth
                                    displayEmpty
                                    error={!!validationErrors[categoryKey]?.title}
                                    size='small'
                                >
                                    <MenuItem value='' disabled>
                                        Select Category
                                    </MenuItem>
                                    {Object.keys(options)
                                        .filter(
                                            (option) =>
                                                !Object.values(categories).some(
                                                    (cat) => cat.title === option && cat.title !== categoryData.title,
                                                ),
                                        )
                                        .map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                </Select>

                                <Tooltip title='Delete Category' arrow placement='right'>
                                    <IconButton onClick={() => handleRemoveCategory(categoryKey)} color='error'>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            {validationErrors[categoryKey]?.title && (
                                <Box sx={{ color: 'error.main', mt: -1, mb: 1 }}>
                                    {validationErrors[categoryKey].title}
                                </Box>
                            )}

                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={'center'}>Subcategory</TableCell>
                                        <TableCell align={'center'}>Percentage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {categoryData.subcategories.map((sub, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: '60%', border: 0 }}>
                                                <Typography sx={{ fontSize: 16 }}>{sub.subCategory}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: 0, display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    type='number'
                                                    value={sub.percentage}
                                                    onChange={(e) =>
                                                        handleChange(categoryKey, index, 'percentage', e.target.value)
                                                    }
                                                    fullWidth
                                                    error={
                                                        !!validationErrors[categoryKey]?.subcategories?.[index]
                                                            ?.percentage
                                                    }
                                                    helperText={
                                                        validationErrors[categoryKey]?.subcategories?.[index]
                                                            ?.percentage
                                                    }
                                                    size='small'
                                                />
                                                <PercentIcon sx={{ marginLeft: 1 }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip
                            title={allCategoriesSelected() ? 'No more categories available' : 'Add Category'}
                            arrow
                            placement='left'
                        >
                            <span>
                                <IconButton
                                    onClick={handleAddCategory}
                                    sx={{ padding: 1 }}
                                    disabled={allCategoriesSelected()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ marginBottom: 2, marginRight: 2 }}>
                <Button onClick={onClose} color='secondary'>
                    Cancel
                </Button>
                <Button onClick={handleSave} color='primary' variant='contained'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    projectMarket: state.data[api.endpoints.projectMarket]?.data || [],
    options: state.data[api.endpoints.projectMarketCategories]?.data || [],
});

const mapDispatchToProps = (dispatch) => ({
    fetchMarket: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectMarket })),
    saveMarket: (data) =>
        dispatch(
            actions.api.data.post.request({
                endpoint: api.endpoints.projectMarket,
                data: data,
            }),
        ),
    fetchOptions: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectMarketCategories })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketShareModal);
