import { all, put, takeEvery } from '@redux-saga/core/effects';

import { actions } from '../../actions';

const _identifiers = {
    dataset: 'ds-tasks',
    annotation: 'annotations',
    annotation_review: 'annotations/review',
    information: 'information',
    project: 'projects/',
    project_legend: 'projects_legends/',
    project_market: 'projects_market/',
};

function* notifRemoveFailure(action) {
    const { endpoint, id, error } = action.payload;
    if (endpoint.includes(_identifiers.dataset))
        yield put(
            actions.notification.add.failure(`Unable to remove the snapshot ${id}:
        ${error?.response?.data} (Code ${error?.response?.status})`),
        );
    if (endpoint.includes(_identifiers.annotation)) {
        yield put(
            actions.notification.add.failure(`Unable to remove the annotation ${id}:
        ${error?.response?.data} (Code ${error?.response?.status})`),
        );
    }
}

function* notifRemoveSuccess(action) {
    const { endpoint, id } = action.payload;
    if (endpoint.includes(_identifiers.dataset))
        yield put(actions.notification.add.success(`The snapshot ${id} has been removed`));
    if (endpoint.includes(_identifiers.annotation))
        yield put(
            actions.notification.add.success(`A task to remove the annotation ${id} has been queued.
        Please follow the progress in the event page`),
        );
}

function* notifPutSuccess(action) {
    const { endpoint, id, response } = action.payload;
    if (endpoint.includes(_identifiers.annotation_review))
        if (id === undefined) yield put(actions.notification.add.success(response?.data?.detail));
        else yield put(actions.notification.add.success(`The annotation ${id} has been reviewed.`));
    else if (endpoint.includes(_identifiers.annotation))
        yield put(
            actions.notification.add.success(`A task to restore the annotation ${id} has been queued.
        Please follow the progress in the event page`),
        );
}

function* notifPutFailure(action) {
    const { endpoint, id, error } = action.payload;
    if (endpoint.includes(_identifiers.annotation_review))
        if (id === undefined)
            yield put(
                actions.notification.add.failure(`Something went wrong while reviewing:
        ${error?.response?.data?.detail} (Code ${error?.response?.status})`),
            );
        else
            yield put(
                actions.notification.add.failure(`Unable to review the annotation ${id}:
        ${error?.response?.data?.detail} (Code ${error?.response?.status})`),
            );
    else if (endpoint.includes(_identifiers.annotation))
        yield put(
            actions.notification.add.failure(`Unable to restore the annotation ${id}:
        ${error?.response?.data} (Code ${error?.response?.status})`),
        );
    else if (endpoint.includes(_identifiers.project)) {
        const errorMessages = error?.response?.data?.detail;
        yield put(
            actions.notification.add.failure(`Failed to update the project:
        ${errorMessages} (Code ${error?.response?.status})`),
        );
    }
}

function* notifPostSuccess(action) {
    const { endpoint } = action.payload;
    if (endpoint.includes(_identifiers.information))
        yield put(actions.notification.add.success(`The latest information has been saved.`));
    if (endpoint.includes(_identifiers.project_legend))
        yield put(actions.notification.add.success(`The legend has been updated.`));
    if (endpoint.includes(_identifiers.project_market))
        yield put(actions.notification.add.success(`The market share has been updated.`));
}

function* notifPostFailure(action) {
    const { endpoint, error } = action.payload;
    if (endpoint.includes(_identifiers.information))
        yield put(
            actions.notification.add.failure(`Unable to save the latest information:
        ${error?.response?.data?.detail} (Code ${error?.response?.status})`),
        );
    if (endpoint.includes(_identifiers.project)) {
        const errorMessages = error?.response?.data?.detail;
        yield put(
            actions.notification.add.failure(`Unable to create the project:
        ${errorMessages} (Code ${error?.response?.status})`),
        );
    }
    if (endpoint.includes(_identifiers.project_legend))
        yield put(
            actions.notification.add.failure(`Unable to update the legend:
        ${error?.response?.data?.detail} (Code ${error?.response?.status})`),
        );
    if (endpoint.includes(_identifiers.project_market))
        yield put(
            actions.notification.add.failure(`Unable to update the market share:
        ${error?.response?.data?.detail} (Code ${error?.response?.status})`),
        );
}

export default function* watchNotifData() {
    yield all([
        yield takeEvery(actions.api.data.remove.failure.toString(), notifRemoveFailure),
        yield takeEvery(actions.api.data.remove.success.toString(), notifRemoveSuccess),
        yield takeEvery(actions.api.data.put.failure.toString(), notifPutFailure),
        yield takeEvery(actions.api.data.put.success.toString(), notifPutSuccess),
        yield takeEvery(actions.api.data.post.failure.toString(), notifPostFailure),
        yield takeEvery(actions.api.data.post.success.toString(), notifPostSuccess),
    ]);
}
