import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { RATING_TYPES_OPTIONS } from '../panels/ProjectCreation/constant';
import { DevelopmentProgress, StepLabel, StepMarkers, TestingProgress, VerticalLinesInBar } from './ProgressBar';

export const ProgressRow = (props) => {
    const {
        testingValue,
        developmentValue,
        developmentSteps,
        testingSteps,
        subject,
        progressTestingValue,
        progressDevelopmentValue,
        type,
        devGraphValue,
        testGraphValue,
        isHeader,
        showDetails,
    } = props;

    const [symbol] = useState(RATING_TYPES_OPTIONS.find((option) => option.value === type)?.symbol);
    const theme = useTheme();

    const styles = {
        outerBox: {
            width: '100%',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
        },
        subject: {
            color: 'white',
            textAlign: 'left',
            fontWeight: 'bold',
            width: '200px',
            marginTop: '44px',
            marginRight: showDetails ? '20px' : '60px',
        },
        rowBody: {
            width: showDetails ? '20%' : '50%',
            marginTop: `${isHeader ? 0 : 40}px`,
            marginRight: '20px',
            textAlign: 'right',
            fontWeight: 'bold',
            transition: 'width 0.5s ease',
        },
        annotationHeader: {
            textAlign: 'center',
            transition: 'width 0.5s ease',
            height: '42px',
        },
        numberColumn: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        numberLeftColumn: {
            width: '50%',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.palette.project.colors[0],
            lineHeight: 0.8,
            display: 'flex',
        },
        numberLeftColumnText: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
            textAlign: 'left',
            width: '70px',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        numberSpan: {
            fontSize: '14px',
            fontWeight: 'normal',
        },
        numberSeparator: {
            textAlign: 'center',
            fontWeight: 'normal',
            margin: '0 5px 3px 5px',
            borderLeft: '1px solid',
            width: '0',
            height: `${showDetails && symbol === '%' ? 30 : 20}px`,
        },
        numberRightColumn: {
            width: '50%',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.palette.project.colors[1],
            lineHeight: 0.8,
            display: 'flex',
        },
        numberRightColumnText: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
            textAlign: 'left',
            width: '70px',
            marginLeft: '20px',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        stepTitle: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        barBox: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
        },
        barInnerBox: {
            flexGrow: 1,
            position: 'relative',
        },
    };

    const calculateLeftPosition = (index) => {
        const nbSteps = 3;
        return index * (100 / nbSteps);
    };

    return (
        <Box sx={styles.outerBox}>
            <Typography variant='body2' component='span' sx={styles.subject}>
                {subject}
            </Typography>

            <Box variant='body1' sx={styles.rowBody}>
                {isHeader && (
                    <Box sx={styles.annotationHeader}>
                        <Typography component='span' variant={'body2'} sx={{ fontWeight: 'bold' }}>
                            Annotations
                        </Typography>
                    </Box>
                )}
                <Box sx={styles.numberColumn}>
                    <Box sx={styles.numberLeftColumn}>
                        <Typography component='span' sx={styles.numberLeftColumnText}>
                            <CSSTransition
                                in={!showDetails}
                                timeout={200}
                                classNames='details-transition'
                                unmountOnExit
                            >
                                <>
                                    <span style={styles.numberSpan}>{'Level ' + testGraphValue}</span>
                                    <span style={styles.numberSpan}>
                                        {' (' +
                                            (testGraphValue > 0 ? testingSteps[testGraphValue - 1] : 0) +
                                            symbol +
                                            ') '}
                                    </span>
                                </>
                            </CSSTransition>
                        </Typography>
                        <Box sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {testingValue}
                            {symbol === '%' && (
                                <CSSTransition
                                    in={showDetails}
                                    timeout={1}
                                    classNames='details-transition'
                                    unmountOnExit
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                                        <br /> ({(progressTestingValue || 0).toFixed(0)}%)
                                    </span>
                                </CSSTransition>
                            )}
                        </Box>
                    </Box>

                    <Box sx={styles.numberSeparator}></Box>

                    <Box sx={styles.numberRightColumn}>
                        <Box sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {developmentValue}
                            {symbol === '%' && (
                                <CSSTransition
                                    in={showDetails}
                                    timeout={1}
                                    classNames='details-transition'
                                    unmountOnExit
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                        <br /> ({(progressDevelopmentValue || 0).toFixed(0)}%)
                                    </span>
                                </CSSTransition>
                            )}
                        </Box>

                        <Typography component='span' sx={styles.numberRightColumnText}>
                            <CSSTransition
                                in={!showDetails}
                                timeout={200}
                                classNames='details-transition'
                                unmountOnExit
                            >
                                <>
                                    <span style={styles.numberSpan}>{'Level ' + devGraphValue}</span>
                                    <span style={styles.numberSpan}>
                                        {' (' +
                                            (devGraphValue > 0 ? developmentSteps[devGraphValue - 1] : 0) +
                                            symbol +
                                            ') '}
                                    </span>
                                </>
                            </CSSTransition>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <CSSTransition in={showDetails} timeout={300} classNames='details-transition' unmountOnExit>
                <Box sx={{ flexGrow: 1, transition: 'width 0.5s ease' }}>
                    {isHeader && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StepMarkers>
                                {['', 'Level 1', 'Level 2', 'Level 3'].map((step, index) => (
                                    <StepLabel key={index} left={calculateLeftPosition(index)}>
                                        <Typography component='span' variant='body2' sx={styles.stepTitle}>
                                            {step}
                                        </Typography>
                                    </StepLabel>
                                ))}
                            </StepMarkers>
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: `${isHeader ? 4 : 20}px` }}>
                        <StepMarkers>
                            {[0, ...testingSteps].map((step, index) => {
                                const active = index <= testGraphValue;
                                const color = active ? theme.palette.project.colors[0] : theme.palette.common.darkgray;
                                const bold = active ? 'bold' : 'normal';

                                return (
                                    <StepLabel key={index} left={calculateLeftPosition(index)}>
                                        <Typography
                                            variant='body2'
                                            component='span'
                                            sx={{ fontSize: '0.7rem', lineHeight: 2, color: color, fontWeight: bold }}
                                        >
                                            {step}
                                            <span style={{ fontSize: '0.6rem' }}>{symbol !== '<' && symbol}</span>
                                        </Typography>
                                    </StepLabel>
                                );
                            })}
                        </StepMarkers>
                    </Box>

                    <Box sx={styles.barBox}>
                        <Box sx={styles.barInnerBox}>
                            {symbol === '%' ? (
                                <TestingProgress
                                    variant='determinate'
                                    value={Math.min(
                                        (progressTestingValue / testingSteps[testingSteps.length - 1]) * 100,
                                        100,
                                    )}
                                />
                            ) : (
                                <TestingProgress variant='determinate' value={progressTestingValue} />
                            )}
                            <VerticalLinesInBar steps={testingSteps.length}>
                                {[0, ...testingSteps].map((_, index) => {
                                    if (index === 0 || index === testingSteps.length) {
                                        return <div key={index} />;
                                    }
                                    return <div key={index} className={'bar'} />;
                                })}
                            </VerticalLinesInBar>
                        </Box>
                    </Box>

                    <Box sx={styles.barBox}>
                        <Box sx={styles.barInnerBox}>
                            {symbol === '%' ? (
                                <DevelopmentProgress
                                    variant='determinate'
                                    value={Math.min(
                                        (progressDevelopmentValue / developmentSteps[developmentSteps.length - 1]) *
                                            100,
                                        100,
                                    )}
                                />
                            ) : (
                                <DevelopmentProgress variant='determinate' value={progressDevelopmentValue} />
                            )}
                            <VerticalLinesInBar steps={developmentSteps.length}>
                                {[0, ...developmentSteps].map((_, index) => {
                                    if (index === 0 || index === developmentSteps.length) {
                                        return <div key={index} />;
                                    }
                                    return <div key={index} className={'bar'} />;
                                })}
                            </VerticalLinesInBar>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: `-6px` }}>
                        <StepMarkers>
                            {[0, ...developmentSteps].map((step, index) => {
                                const active = index <= devGraphValue;
                                const color = active ? theme.palette.project.colors[1] : theme.palette.common.darkgray;
                                const bold = active ? 'bold' : 'normal';

                                return (
                                    <StepLabel key={index} left={calculateLeftPosition(index)}>
                                        <Typography
                                            variant='body2'
                                            component='span'
                                            sx={{ fontSize: '0.7rem', lineHeight: 2, color: color, fontWeight: bold }}
                                        >
                                            {step}
                                            <span style={{ fontSize: '0.6rem' }}>{symbol !== '<' && symbol}</span>
                                        </Typography>
                                    </StepLabel>
                                );
                            })}
                        </StepMarkers>
                    </Box>
                </Box>
            </CSSTransition>
        </Box>
    );
};
